<template>
    <div class="d-flex" style="width: 100%;">
        <div style="width: 50%; flex-direction: column; align: start; justify-content: center" class="d-flex" >
            <span class="ideaText">Have a Idea In Mind ? </span>
            <span class="ideaText">Let’s Get To WOrk 👋</span>
        </div>
        <div style="width: 50%; flex-direction: column; justify-content: center" class="d-flex">
            <v-text-field label="Name" variant="outlined" ></v-text-field>
            <v-text-field label="E-Mail" variant="outlined" ></v-text-field>
            <v-text-field label="Phone Number" variant="outlined" ></v-text-field>
            <v-textarea label="Message" variant="outlined"></v-textarea>
            <div style="align-items: flex-end; display: flex; justify-content: flex-end; width: 100%;">
                <v-btn
                    style="background: #D3E97A; color: black; border-radius: 100px; height: 54px; width: auto; margin-top: 10px; font-family: Poppins; width: 167px"
                >
                    Submit
                </v-btn>
            </div>
        </div>
    </div>
    <div class="bottom-bar">
        <div class="socialMedia">
            <span style="font-family: poppins; font-weight: 500; font-size: 24px; text-align: center;">To learn more
                about me</span>
            <div style="display: flex; gap: 100px; cursor: pointer;">
                <img :src="require('@/assets/linkedIN.svg')" />
                <img :src="require('@/assets/instagram.svg')" />
                <img :src="require('@/assets/medium.svg')" />
                <img :src="require('@/assets/github.svg')" />
            </div>
        </div>
        <div class="handCrafted">
            <span>© 2024 | Handcrafted with  🤍  by Manickam</span>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'contactUs',
    data(){
        return{
            letsTalkBtnHover: false
        }
    }
});
</script>
<style scoped>
.ideaText{
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 70px;
    text-align: start;
    line-height: 64px;
}


.socialMedia {
    display: flex;
    gap: 38px;
    line-height: 12px;
    margin-top: 32px;
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
}

.bottom-bar{
    display: flex;
    align-items: end;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
    border-top: 1px solid #484848;
}

.handCrafted{
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
}
</style>