<template>
  <v-app>
    <div class="container main_layout">
      <TopBar class="topBar" @clickedTab="selectedTab"></TopBar>
      <section id="myhome" class="snap-section" ref="home">
        <v-container class="fill-height" fluid>
          <v-row justify="center" align="center">
            <myHome @movetoContactUS="selectedTab('contactme')"></myHome>
          </v-row>
        </v-container>
      </section>

      <section id="aboutme" class="snap-section" ref="about">
        <v-container class="fill-height" fluid>
          <v-row justify="center" align="center">
            <aboutMe></aboutMe>
          </v-row>
        </v-container>
      </section>

      <section id="techStack" class="snap-section" ref="techStack">
        <v-container class="fill-height" fluid>
          <v-row justify="center" align="center">
            <techStack></techStack>
          </v-row>
        </v-container>
      </section>

      <section id="eduExperience" class="snap-section" ref="eduExperience">
        <v-container class="fill-height" fluid>
          <v-row justify="center" align="center">
            <EduExperience></EduExperience>
          </v-row>
        </v-container>
      </section>

      <section id="whatIDo" class="snap-section" ref="whatIDo">
        <v-container class="fill-height" fluid>
          <v-row justify="center" align="center">
            <whatIDo></whatIDo>
          </v-row>
        </v-container>
      </section>

      <section id="contactme" class="snap-section" ref="contactme">
        <v-container class="fill-height" fluid>
          <v-row justify="center" align="center">
            <contactme></contactme>
          </v-row>
        </v-container>
      </section>

      <!-- Mobile UI Message Overlay -->
      <div v-if="isMobile" class="mobile-message">
        We are cooking for mobile UI
      </div>
    </div>
  </v-app>
</template>

<script>
import { defineComponent, ref, computed } from 'vue';
import TopBar from './TopBar.vue';
import myHome from './myHome.vue';
import aboutMe from './aboutMe.vue';
import techStack from './techStack.vue';
import EduExperience from './EduExperience.vue';
import whatIDo from './whatIDO.vue';
import contactme from './contactme.vue';

export default defineComponent ({
  name: 'HelloWorld',
  components: {
    myHome,
    aboutMe,
    techStack,
    TopBar,
    EduExperience,
    whatIDo,
    contactme
  },
  mounted(){
  },
  methods:{
    selectedTab(section) {
      this.$refs[section]?.scrollIntoView({ behavior: 'smooth' });
    }
  },
  setup() {
    const width = ref(window.innerWidth);

    const isMobile = computed(() => width.value <= 600);
    console.log(isMobile);
    const updateWidth = () => {
      width.value = window.innerWidth;
    };

    // Attach the event listener correctly for resize
    window.addEventListener('resize', updateWidth);

    return { isMobile };
  },

});
</script>

<style>
.container {
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  max-width: 1200px;
  margin: 0 auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.container::-webkit-scrollbar {
    display: none;
}

.snap-section {
  height: 100vh;
  width: 100%;
  scroll-snap-align: start;
  display: flex;
  align-items: center;
  padding-top: 50px;
}

.topBar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
}

.section {
  width: 100%;
  height: 100vh;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.main_layout {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .container {
  height: 100vh;
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  max-width: 100%;
  margin: 0 auto;
  padding-left: 50px;
  padding-right: 50px;
}
    .topBar {
        width: calc(100% - 20px);
    }
}

html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
