<template>
    <div class="d-flex" style="flex-direction: column; max-width: 100%;">
        <div class="heading">
            <h1>
                WHo AM i
            </h1>
        </div>
        <div class="content" style="text-align: center;">
            <span>
                As a Full Stack Software Development Engineer with over two years of experience, I specialize in
                creating comprehensive solutions that bridge front-end and back-end technologies, utilizing robust AWS
                cloud environments. My expertise extends deeply into DevOps practices, allowing for seamless integration
                and deployment processes that enhance product scalability and performance. I am committed to continuous
                improvement, consistently exploring the latest advancements in technologies to refine and advance my
                development projects.
            </span>
        </div>
        <div class="d-flex" style="gap: 50px; margin-top: 3rem; min-width: 100%;">
            <div class="experience">
                <div>
                    <span
                        style="font-weight: 400; font-size: 110px; text-align: center; color: #D3E97A; font-family: 'Inconsolata'; margin-right: -10px;">02+</span>
                </div>
                <div style="width: 40%; text-align: start;">
                    <span style="flex-wrap: wrap;">
                        YEARS EXPERIENCE
                    </span>
                </div>
            </div>
            <div class="experience">
                <div>
                    <span
                        style="font-weight: 400; font-size: 110px; text-align: center; color: #D3E97A; font-family: 'Inconsolata'; margin-right: -10px;">05+</span>
                </div>
                <div style="width: 40%; text-align: start;">
                    <span style="flex-wrap: wrap;">
                        COMPLETED WORKS
                    </span>
                </div>
                
            </div>
        </div>
    </div>

</template>
<script>
import { defineComponent } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default defineComponent({
    name: 'aboutMe',
    data() {
        return {
            
        }
    },
    mounted(){
        AOS.init({
            once: false,
            duration: 1000,
            offset: 100,
            easing: 'ease-in-out'
        })
    },
    updated() {
        AOS.refresh();
    }  
})
</script>
<style>
.heading {
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 32px;
    text-align: center;
    justify-content: center;
}

.content span {
    font-family: Poppins;
    font-weight: 200;
    font-size: 22px;
    text-align: center;
    line-height: 2;
    color: #FFFFFFCC
}

.experience {
    height: 228px;
    max-width: 50%;
    display: flex;
    gap: 60px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #0A0A0A 0%, rgba(33, 32, 32, 0.6) 100%);
    border: solid 1px #FFFFFF33;
    border-radius: 10px;
}

.learnMore {
    height: 228px;
    width: 50%;
    display: flex;
    gap: 38px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #0A0A0A 0%, rgba(33, 32, 32, 0.6) 100%);
    border: solid 1px #FFFFFF33;
    border-radius: 10px;
}

.experience span {
    font-family: Bebas Neue;
    font-weight: 400;
    font-size: 60px;
    line-height: 1;
}
</style>