<template>
    <div class="home_layout">
        <div style="align-items: center;" data-aos="fade-right" data-aos-offset="200" data-aos-easing="ease-in-sine"
            data-aos-duration="1000" data-aos-anchor-placement="top-bottom">
            <div class="Vanakkam">
                <span class="vanakkam_text">Vanakkam(🙏);</span>
                <span class="Im_text">I'm</span>
            </div>
            <h1 class="name">Manickam.</h1>
            <div class="domain_text">
                <span>Full Stack Developer | DevOps</span>
            </div>
            <div class="tagLine">
                <p>Step into my codeverse, where every semicolon smiles and bugs begone! 😄🐞</p>
            </div>
            <div class="d-flex" style="align-items: center; gap: 20px;">
                <v-btn
                    style="background: #D3E97A; color: black; border-radius: 100px; height: 54px; width: auto; margin-top: 42px; font-family: Poppins;"
                    @mouseover="letsTalkBtnHover = true" @mouseleave="letsTalkBtnHover = false" @click="movetoContactUS()">
                    Let's Talk
                    <template v-slot:append>
                        <v-icon v-if="letsTalkBtnHover">{{ "mdi-arrow-top-right" }}</v-icon>
                        <v-icon v-else>{{ "mdi-checkbox-blank-circle" }}</v-icon>
                    </template>
                </v-btn>
                <v-btn
                    style="background: #D3E97A; color: black; border-radius: 100px; height: 54px; width: auto; font-family: Poppins; margin-top: 42px;"
                    @mouseover="downloadBtnHoverd = true" @mouseleave="downloadBtnHoverd = false" @click="downloadResume()">
                    Download Resume
                    <template v-slot:append>
                        <v-icon v-if="!downloadBtnHoverd">{{ "mdi-checkbox-blank-circle" }}</v-icon>
                        <img v-else :src="require('@/assets/download_icon.svg')" alt="Download Icon"
                            style="height: auto; width: 40px; margin-left: 10px;">
                    </template>
                </v-btn>
            </div>

        </div>
        <div data-aos="fade-left" data-aos-offset="200" data-aos-easing="ease-in-sine" data-aos-duration="1000"
            data-aos-anchor-placement="top-bottom">
            <span class="tagIcon">{{ '</>' }}</span>
            <img :src="require('@/assets/Home_Page_Img.svg')" style="height: 24rem; width: auto;" />
            <img class="stackIcon" :src="require('@/assets/stackIcon.svg')" />
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
export default defineComponent({
    name: 'myHome',
    emits: ['movetoContactUS'],
    data() {
        return {
            downloadBtnHoverd: false,
            letsTalkBtnHover: false
        }
    },

    mounted() {
        AOS.init({
            once: false,
            duration: 1000,
            offset: 100,
            easing: 'ease-in-out'
        })
    },

    methods:{
        movetoContactUS(){
            this.$emit('movetoContactUS')
        },
        downloadResume(){
            window.open('https://drive.google.com/file/d/1Nlu68pVaCT29xZ7WJGWCNG_72p_dptOq/view', '_blank');
        }
    },

    updated() {
        AOS.refresh();
    }
});
</script>
<style>
.home_layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
}

.home_layout>div {
    flex: 1;
}

.home_layout>div:last-child {
    justify-content: flex-end;
    display: flex;
}

.Vanakkam {
    display: flex;
    gap: 8px;
}

.vanakkam_text {
    font-family: 'Fira Code';
    font-weight: 600;
    font-size: 32px;
}

.Im_text {
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 32px;
}

.name {
    font-family: 'Bebas Neue';
    font-weight: 100;
    font-size: 100px;
    margin-top: 0;
    line-height: 1;
}

.domain_text {
    font-family: 'poppins';
    font-size: 32px;
    line-height: 1.2;
    font-weight: 500;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
}

.tagLine {
    font-family: 'poppins 100', sans-serif;
    font-weight: 100;
    font-size: 30px;
    line-height: 35px;
    color: #C7C7C7;
}

.tagIcon {
    font-family: 'Fira Code';
    color: #FFFFFF80;
    font-weight: 600;
    font-size: 50px;
    letter-spacing: 1px;
    transform: rotate(-18deg);
    margin-top: -56px;
    margin-right: -96px;
}
</style>