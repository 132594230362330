// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(
  {
    theme: {
      defaultTheme: 'myTheme',
      themes: {
        myTheme: {
          dark: true,  // Enables dark theme globally
          colors: {
            background: '#000000',  // black
            surface: '#121212',  // Material Design dark theme surface color
            primary: '#6200ea',  // example primary color
            secondary: '#03dac6',  // example secondary color
            accent: '#82B1FF',  // example accent color
            // Define other colors as needed
          }
        }
      }
    }
  }
)
